import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Box, FormHelperText, FormControl, IconButton, Link } from "@material-ui/core";
import * as pick from "lodash/pick";
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import countries from "iso-3166-country-list";
import Alert from "@material-ui/lab/Alert";
import { useHistory, useParams } from "react-router-dom";
import { Input, Autocomplete } from "components/Shared/mui-formik-inputs";
import { SupplierLocationSchema } from "constants/validation-schemas";
import { SupplierLocationFormAllowedFields } from "constants/forms-submit-allowed-fields";
import { PATHS } from "util/appConstants";
import SelectGeoCoordinates from "components/Customers/SelectGeoCoordinates";
import FormCancelSaveButton from "components/Shared/FormCancelSaveButtons";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles({
  _heading: {
    font: "normal normal normal 28px/40px Questrial",
    color: "#121212",
  },
  _icons: {
    color: "#ADADAD",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    margin: "0 16px 0 0px",
    fontSize: "35px",
  },
  _save: {
    "&:hover": {
      transform: "scale(1.3)",
      color: "#6F9CEB",
    },
    "&:hover + span": {
      display: "block",
    },
  },
  _close: {
    "&:hover": {
      transform: "scale(1.3)",
      color: "#525252",
    },
    "&:hover + span": {
      display: "block",
    },
  },
  _subheading: {
    font: "normal normal 500 22px/32px Roboto",
    color: " #121212",
    marginTop: "44px",
    marginBottom: "40px",
  },
  _dflex: {
    display: "flex",
    alignItems: "center",
  },
});

const SupplierLocationForm = ({ initialValues, onSubmit, action, suppliers }) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const [selectedFile, setSelectedFile] = useState(null);
  const validateAddress = (values) => {
    const errors = {};
    if (!values.street_number || !values.zipcode || !values.city || !values.country) {
      errors.address = t(
        "Invalid address. Please provide and select the full address with street, house number, zipcode, city, and country."
      );
    }
    return errors;
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: SupplierLocationSchema,
    validate: validateAddress,
    initialValues: {
      name: "", // required
      street: "", // required
      street_number: "", // required
      city: "", // required
      zipcode: "", // required
      country: "", // required
      email: "", // required
      phone: "", // required
      latitude: initialValues?.coordinates?.coordinates[1] || 0, // required
      longitude: initialValues?.coordinates?.coordinates[0] || 0, // required
      supplier_id: initialValues?.supplier_id ?? null,
      formatted_address: initialValues?.formatted_address ?? null,
      filename: initialValues?.filename ?? null,
      image_url: initialValues?.image_url ?? "#",
      ...initialValues,
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const formData = new FormData();
        const filteredValues = pick(values, SupplierLocationFormAllowedFields);
        Object.keys(filteredValues).forEach((key) => {
          formData.append(key, filteredValues[key]);
        });

        if (action === "ADD") {
          formData.append("filename", selectedFile);
        } else if (action === "EDIT") {
          if (selectedFile) {
            formData.append("filename", selectedFile);
          } else {
            formData.append("keepExistingFile", "true");
            formData.append("filename", initialValues.filename);
          }
        }
        await onSubmit(formData);
      } catch (err) {
        setSubmitting(false);
      }
    },
  });

  const {
    values,
    handleChange,
    setFieldValue,
    errors,
    handleSubmit,
    submitCount,
    isValid,
    isSubmitting,
    setFieldTouched,
  } = formik;
  let { handleBlur } = formik;

  if (!submitCount) {
    handleBlur = null;
  }

  const closeCustomerHandler = () => {
    action === "ADD"
      ? history.push(PATHS.admin.supplierLocations)
      : history.push(PATHS.admin.supplierLocationsDetail.replace(":id", id));
  };

  const cancelButtonRef = useRef(null);
  const saveButtonRef = useRef(null);

  const lastElementRef = useRef(null);

  useEffect(() => {
    const handleTabPress = (e) => {
      if (e.key === "Tab") {
        if (lastElementRef.current && lastElementRef.current.contains(document.activeElement)) {
          e.preventDefault();
          if (cancelButtonRef.current) {
            window.scrollTo({ top: 0, behavior: "smooth" });
            cancelButtonRef.current.focus();
          }
        }
      }
    };

    document.addEventListener("keydown", handleTabPress);
    return () => {
      document.removeEventListener("keydown", handleTabPress);
    };
  }, []);

  const reEvaluateAddress = () => {
    if (errors.street_number || errors.zipcode || errors.city || errors.country) {
      const isAddressValid = values.street_number && values.zipcode && values.city && values.country;
      if (isAddressValid) {
        setFieldTouched("street_number", false);
        setFieldTouched("zipcode", false);
        setFieldTouched("city", false);
        setFieldTouched("country", false);
      }
    }
  };

  useEffect(() => {
    reEvaluateAddress();
  }, [values.street_number, values.zipcode, values.city, values.country, values.tour_id]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      setFieldValue("filename", file);
      setFieldTouched("filename", true, false);
      setTimeout(() => {
        formik.validateField("filename");
      }, 100);
    }
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
    setFieldValue("filename", null);
  };

  return (
    <Box height="100%">
      <Box display="flex" mb={4}>
        <Box flex={2}>
          <Typography className={classes._heading} variant="h4">
            {action === "ADD" ? t("New Customer") : t("Edit Customer")}
          </Typography>
        </Box>
        <Box flex={2} textAlign="right" position="relative">
          <Box position="absolute" right={0}>
            <FormCancelSaveButton
              disabled={!isValid || isSubmitting}
              onCancel={closeCustomerHandler}
              onSave={handleSubmit}
              ref={{ cancelRef: cancelButtonRef, saveRef: saveButtonRef }}
            />
          </Box>
        </Box>
      </Box>
      <Typography className={classes._subheading} variant="h5">
        {t("Basic Data")}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Autocomplete
            onBlur={handleBlur}
            name="supplier_id"
            label="Supplier"
            errors={errors}
            value={values.supplier_id}
            settings={{
              disableClearable: true,
              valueProp: "id",
              labelProp: "name",
            }}
            onChange={(selected) => {
              setFieldValue("supplier_id", selected.id);
            }}
            options={suppliers}
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Input
            label={t("Name")}
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            errors={errors}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Input
            label={t("Telephone Number")}
            name="phone"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.phone}
            errors={errors}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Input
            label={t("E-Mail")}
            type="email"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            errors={errors}
            required
          />
        </Grid>
      </Grid>
      <div style={{ display: "none" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Input
              label={t("Street")}
              name="street"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.street}
              errors={errors}
              required
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label={t("House No.")}
              name="street_number"
              onChange={handleChange}
              value={values.street_number}
              errors={errors}
              required
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={2} lg={1}>
            <Input label={t("Zipcode")} name="zipcode" value={values.zipcode} errors={errors} required disabled />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Input
              label={t("City")}
              name="city"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.city}
              errors={errors}
              required
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Autocomplete
              onBlur={handleBlur}
              name="country"
              label="Country"
              errors={errors}
              value={values.country}
              settings={{
                disableClearable: true,
                valueProp: "code",
                labelProp: "name",
              }}
              onChange={(selected) => {
                setFieldValue("country", selected.code);
              }}
              options={countries.map(({ code, name }) => ({
                code,
                name: i18n.language === "en" ? name : t(`country.${code}`),
              }))}
              required
              disabled
            />
          </Grid>
        </Grid>
      </div>
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Input
              label={t("Latitude")}
              name="latitude"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.latitude}
              errors={errors}
              disabled
              required
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Input
              label={t("Longitude")}
              name="longitude"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.longitude}
              errors={errors}
              disabled
              required
            />
          </Grid>
        </Grid>
      </>
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {errors.address && <Alert severity="error">{errors.address}</Alert>}
            <SelectGeoCoordinates
              onChange={({ latitude, longitude, street, street_number, zipcode, city, country, formatted_address }) => {
                const fields = {
                  latitude,
                  longitude,
                  street,
                  street_number,
                  zipcode,
                  city,
                  country,
                  formatted_address,
                };

                Object.entries(fields).forEach(([key, value]) => {
                  if (value) {
                    setFieldValue(key, value);
                  }
                });
              }}
              latitude={values.latitude || 52.52321191756548}
              longitude={values.longitude || 13.405897492100648}
              initialInputValue={
                values.id
                  ? values.formatted_address
                    ? values.formatted_address
                    : `${values.street} ${values.street_number}, ${values.zipcode}, ${values.city}, ${values.country}`
                  : ""
              }
            />
          </Grid>
        </Grid>
        <Typography className={classes._subheading} variant="h5">
          {t("Logo")}
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl fullWidth error={Boolean(errors.filename)} style={{ marginTop: "20px" }}>
              <Box display="flex" alignItems="center">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  style={{
                    padding: "16px",
                    border: "1px dashed #c4c4c4",
                    borderRadius: "8px",
                    cursor: "pointer",
                    textAlign: "center",
                    marginRight: "10px",
                  }}
                >
                  <input
                    type="file"
                    id="file-input"
                    onChange={handleFileChange}
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <label htmlFor="file-input" style={{ cursor: "pointer", color: "#000" }}>
                    <Typography variant="body1" color="textSecondary">
                      {t("Upload Logo")}
                    </Typography>
                  </label>
                </Box>

                {/* Display the filename link if available */}
                {(selectedFile || values?.filename) && (
                  <Box display="flex" alignItems="center" maxWidth="150px" overflow="hidden">
                    <Link
                      href={selectedFile ? URL.createObjectURL(selectedFile) : values.image_url}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        color: "black",
                        marginRight: "10px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {selectedFile ? selectedFile.name : values.filename}
                    </Link>
                    <IconButton size="small" onClick={handleFileRemove} aria-label="delete file">
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}
              </Box>
              {errors.filename && <FormHelperText>{t(errors.filename)}</FormHelperText>}
            </FormControl>
          </Grid>
        </Grid>
      </>
    </Box>
  );
};
export default SupplierLocationForm;
