import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { PATHS } from "util/appConstants";
import {
  selectTour,
  selectTourStatus,
  getTour,
  editTour,
} from "redux/slices/tourSlice";
import TourForm from "components/Tours/form";
import Navbar from "components/Navbar";
import LightLayout from "components/Shared/LightLayout";
import CustomersNavbar from "components/Masterbar/CustomersBar";
import {
  getSupplierLocations,
  selectSupplierLocations,
} from "redux/slices/supplierLocationsSlice";

const currentAction = "EDIT";

const EditTour = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const loading = useSelector(selectTourStatus);
  const tour = useSelector(selectTour);
  const supplierLocations = useSelector(selectSupplierLocations);

  useEffect(() => {
    if (id) {
      dispatch(getTour(id));
    }
  }, [dispatch, id]);

  const onSubmit = async (payload) => {
    await dispatch(editTour(id, payload));

    history.push(PATHS.tours.root);
  };

  useEffect(() => {
    dispatch(getSupplierLocations());
  }, [dispatch]);

  let tourWithAllowedLocations = null;
  if (tour) {
    tourWithAllowedLocations = {
      ...tour,
      allowed_supplier_locations: tour.ToursAlloweds.map(
        (tourAllowed) => tourAllowed.supplier_location_id
      ),
    };
  }

  return (
    <>
      <Navbar />
      <CustomersNavbar />
      <LightLayout doublebar loading={loading || !tour}>
        <TourForm
          initialValues={tourWithAllowedLocations}
          onSubmit={onSubmit}
          action={currentAction}
          supplierLocations={supplierLocations}
        />
      </LightLayout>
    </>
  );
};

export default EditTour;
