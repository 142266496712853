import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import MaterialTable from "material-table";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import { SUPPLIER_LOCATIONS_TABLE_COLUMNS } from "constants/ui-constants";
import { getColumns, getActions, getLocalization } from "util/table-utils";
import { mapTableData } from "util/helpers";
import { PATHS } from "util/appConstants";
import withConfirm from "components/dialogs/delete";
import Navbar from "components/Navbar";
import DarkLayout from "components/Shared/DarkLayout";
import { selectUser } from "redux/slices/userSlice";
import AdminBar from "components/Masterbar/AdminBar";
import {
  getSupplierLocations,
  selectSupplierLocations,
  selectSupplierLocationsStatus,
} from "redux/slices/supplierLocationsSlice";

// Using MUI's new sx prop for styles or use styled components
const FilterIcon = styled("i")(({ theme }) => ({
  color: "#525252",
  fontSize: "12px",
}));

const tableTitle = "SUPPLIERLOCATIONS";

const SupplierLocationList = ({ confirm }) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const history = useHistory();
  // const loading = useSelector(selectTourStatus);
  const user = useSelector(selectUser);

  const loading = useSelector(selectSupplierLocationsStatus);
  const supplierLocations = useSelector(selectSupplierLocations);

  useEffect(() => {
    if (!loading) {
      dispatch(getSupplierLocations());
    }
  }, [dispatch, supplierLocations]);

  const actions = getActions(
    tableTitle,
    () => () => {},
    () => addHandler(),
    null,
    null,
    null,
    t,
    user
  );
  const addHandler = () => {
    history.push(PATHS.admin.supplierLocationsAdd);
  };

  return (
    <>
      <Navbar />
      <AdminBar />
      <DarkLayout doublebar loading={loading}>
        <div className="custom-table-styles">
          <MaterialTable
            icons={{
              Filter: () => <FilterIcon className={clsx("fas fa-filter")} />,
            }}
            style={{ display: "flex", flexDirection: "column" }}
            data={mapTableData(supplierLocations)}
            title={t(tableTitle)}
            columns={getColumns(SUPPLIER_LOCATIONS_TABLE_COLUMNS(t), t)}
            onRowClick={(e, rowData) => history.push(PATHS.admin.supplierLocationsDetail.replace(":id", rowData.id))}
            actions={actions}
            localization={getLocalization(t)}
            options={{
              pageSize: 50,
              pageSizeOptions: [50, 100],
              actionsColumnIndex: -1,
              searchFieldAlignment: "left",
              showTitle: false,
              filtering: true,
              emptyRowsWhenPaging: false,
              headerStyle: {
                backgroundColor: "#121212",
                color: "white",
                borderBottom: "1px solid #525252",
                font: "normal normal normal 12px/24px Roboto",
                fontWeight: "bold",
              },
              cellStyle: {
                backgroundColor: "#121212",
                color: "white",
                border: "none",
                font: "normal normal normal 12px/24px Roboto",
                padding: "0 16px",
              },
              searchFieldStyle: {
                color: "#F5F5F5",
              },
              filterCellStyle: {
                color: "#F5F5F5",
              },
              rowStyle: { height: "38px" },
            }}
          />
        </div>
      </DarkLayout>
    </>
  );
};

export default withConfirm(SupplierLocationList);
