export const sortingType = (user) => {
  if(user?.permissions?.routes?.export_sorting_zipcode) {
    return 'zipcode'
  }
  else if(user?.permissions?.routes?.export_sorting_priority) {
    return 'priority'
  }
  else if(user?.permissions?.routes?.export_sorting_routing) {
    return 'routing'
  }
  else {
    return 'alphabetical'
  }
}