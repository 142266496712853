import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { PATHS } from "util/appConstants";
import Navbar from "components/Navbar";
import LightLayout from "components/Shared/LightLayout";
import SupplierLocationForm from "components/SupplierLocations/form";
import AdminBar from "components/Masterbar/AdminBar";
import {
  editSupplierLocation,
  getSupplierLocation,
  selectSupplierLocation,
  selectSupplierLocationsStatus,
} from "redux/slices/supplierLocationsSlice";
import { getSuppliers, selectSuppliers } from "redux/slices/supplierSlice";
import { useParams } from "react-router-dom";

const currentAction = "EDIT";
const EditSupplierLocation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(selectSupplierLocationsStatus);
  const supplierLocation = useSelector(selectSupplierLocation);
  const suppliers = useSelector(selectSuppliers);
  const { id } = useParams();

  useEffect(() => {
    if (id && !loading) {
      dispatch(getSupplierLocation(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (!suppliers.length && !loading) {
      dispatch(getSuppliers());
    }
  }, [dispatch, suppliers]);

  const onSubmit = async (payload) => {
    await dispatch(editSupplierLocation(id, payload)).then((res) =>
      res !== undefined ? history.push(PATHS.admin.supplierLocations) : ""
    );
  };

  return (
    <>
      <Navbar />
      <AdminBar />
      <LightLayout doublebar loading={loading || !supplierLocation}>
        <SupplierLocationForm
          action={currentAction}
          initialValues={supplierLocation}
          onSubmit={onSubmit}
          suppliers={suppliers}
        />
      </LightLayout>
    </>
  );
};

export default EditSupplierLocation;
