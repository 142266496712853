import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { selectUser } from "redux/slices/userSlice";
import { PATHS } from "../../util/appConstants";

export default () => {
  const user = useSelector(selectUser);

  if (user?.permissions?.routesList || user?.permissions?.routes?.list) {
    return <Redirect to={PATHS.routes.current} />;
  } else if (user?.permissions?.ordersList || user?.permissions?.orders?.list) {
    return <Redirect to={PATHS.orders.root} />;
  } else if (
    !user?.permissions?.showMasterData &&
    !user?.permissions?.customers?.list
  ) {
    return <Redirect to={PATHS.tours.root} />;
  }

  return <Redirect to={PATHS.customers.root} />;
};
