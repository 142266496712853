import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import * as pick from "lodash/pick";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import countries from "iso-3166-country-list";
import Alert from "@material-ui/lab/Alert";
import { useHistory, useParams } from "react-router-dom";
import { Input, Select, Checkbox, Autocomplete } from "components/Shared/mui-formik-inputs";
import { CustomerSchema } from "constants/validation-schemas";
import { CustomerFormAllowedFields } from "constants/forms-submit-allowed-fields";
import { PATHS } from "util/appConstants";
import SelectGeoCoordinates from "components/Customers/SelectGeoCoordinates";
import FormCancelSaveButton from "components/Shared/FormCancelSaveButtons";
import { isAdminUserSelector, selectUser } from "redux/slices/userSlice";
import { getCustomers } from "redux/slices/customerSlice";
import { useDidUpdateEffect } from "../../util/use-did-update-effect";
import { usePermission } from "../../hooks/usePermission";
import { getSuppliers, selectSuppliers } from "redux/slices/supplierSlice";

const useStyles = makeStyles({
  _heading: {
    font: "normal normal normal 28px/40px Questrial",
    color: "#121212",
  },
  _icons: {
    color: "#ADADAD",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    margin: "0 16px 0 0px",
    fontSize: "35px",
  },
  _save: {
    "&:hover": {
      transform: "scale(1.3)",
      color: "#6F9CEB",
    },
    "&:hover + span": {
      display: "block",
    },
  },
  _close: {
    "&:hover": {
      transform: "scale(1.3)",
      color: "#525252",
    },
    "&:hover + span": {
      display: "block",
    },
  },
  _subheading: {
    font: "normal normal 500 22px/32px Roboto",
    color: " #121212",
    marginTop: "44px",
    marginBottom: "40px",
  },
  _dflex: {
    display: "flex",
    alignItems: "center",
  },
});

const CustomerForm = ({ initialValues, onSubmit, action, tourList, zipcodes }) => {
  const [similarCustomers, setSimilarCustomers] = React.useState([]);
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const suppliers = useSelector(selectSuppliers);
  const { id } = useParams();

  const isAdminUser = useSelector(isAdminUserSelector);

  const validateAddress = (values) => {
    const errors = {};
    if (!values.street_number || !values.zipcode || !values.city || !values.country) {
      errors.address = t(
        "Invalid address. Please provide and select the full address with street, house number, zipcode, city, and country."
      );
    }
    return errors;
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: CustomerSchema,
    validate: validateAddress,
    initialValues: {
      number: "",
      contact_salutation: "",
      contact_name: "",
      contact_surname: "",
      email_notifications: false,
      tour: "",
      position: "",
      deposit_agreement: "NONE",
      keybox_code: "",
      tour_id: "", // required
      name: "", // required
      street: "", // required
      street_number: "", // required
      city: "", // required
      zipcode: "", // required
      country: "", // required
      email: "", // required
      phone: "", // required
      active: true,
      duplicate: initialValues?.duplicate ?? false, // Archive is treated as duplicate
      ...initialValues,
      latitude: initialValues?.latitude || 0, // required
      longitude: initialValues?.longitude || 0, // required
      tour_automatic_assigned: !initialValues?.tour_manually_assigned ?? true,
      priority: initialValues?.priority ?? false,
      supplier_id: initialValues?.supplier_id ?? null,
      formatted_address: initialValues?.formatted_address ?? null,
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await onSubmit(
          pick(
            {
              ...values,
              tour_manually_assigned: !values.tour_automatic_assigned,
            },
            CustomerFormAllowedFields
          )
        );
      } catch (err) {
        setSubmitting(false);
      }
    },
  });

  const {
    values,
    handleChange,
    setFieldValue,
    errors,
    handleSubmit,
    submitCount,
    isValid,
    isSubmitting,
    setFieldTouched,
  } = formik;
  let { handleBlur } = formik;

  if (!submitCount) {
    handleBlur = null;
  }

  const checkDuplicated = () => {
    if (values.zipcode && values.street_number && !initialValues?.id) {
      dispatch(
        getCustomers(false, {
          zipcode: values.zipcode,
          street_number: values.street_number,
        })
      ).then((results) => {
        const uniqueCustomers = results.reduce((acc, customer) => {
          if (!acc.has(customer.name)) {
            acc.set(customer.name, customer);
          }
          return acc;
        }, new Map());
        const uniqueCustomersArray = Array.from(uniqueCustomers.values());
        uniqueCustomersArray.forEach((uniqueCustomer) => {
          results.forEach((customer) => {
            if (customer.supplier_id === user.supplier_id) {
              if (customer.name === uniqueCustomer.name) {
                uniqueCustomer.id = customer.id;
              }
            }
          });
        });
        setSimilarCustomers(uniqueCustomersArray);
      });
    }
  };

  const customHandleChange = (e) => {
    const { value } = e.target;
    if (values.tour_automatic_assigned) {
      const zipcode = zipcodes.find((zc) => zc.zipcode?.trim().toLowerCase() === (value || "").trim().toLowerCase());

      if (values.tour_automatic_assigned) {
        if (zipcode) {
          setFieldValue("tour_id", zipcode.Tour.id);
        } else {
          const def = tourList.find((t) => t.is_default) || tourList[0];
          if (def) {
            setFieldValue("tour_id", def.id);
          }
        }
      }
    }
  };

  const closeCustomerHandler = () => {
    action === "ADD" ? history.push(PATHS.customers.root) : history.push(PATHS.customers.detail.replace(":id", id));
  };

  useDidUpdateEffect(() => {
    if (values.tour_automatic_assigned) {
      const zipcode = zipcodes.find((zc) => zc.zipcode?.trim().toLowerCase() === values.zipcode.trim().toLowerCase());

      if (zipcode) {
        setFieldValue("tour_id", zipcode.Tour.id);
      }
    }
  }, [values.tour_automatic_assigned]);

  const hideLocationPerm = usePermission("customers.hide_location_fields");
  const showLocationFields = !usePermission("customersHideLocationRelatedFields") && !hideLocationPerm;

  const cancelButtonRef = useRef(null);
  const saveButtonRef = useRef(null);

  const lastElementRef = useRef(null);

  useEffect(() => {
    const handleTabPress = (e) => {
      if (e.key === "Tab") {
        if (lastElementRef.current && lastElementRef.current.contains(document.activeElement)) {
          e.preventDefault();
          if (cancelButtonRef.current) {
            window.scrollTo({ top: 0, behavior: "smooth" });
            cancelButtonRef.current.focus();
          }
        }
      }
    };

    document.addEventListener("keydown", handleTabPress);
    return () => {
      document.removeEventListener("keydown", handleTabPress);
    };
  }, []);

  useEffect(() => {
    dispatch(getSuppliers());
  }, [dispatch]);

  useEffect(() => {
    if ((values.zipcode || values.street_number) && !initialValues?.id) {
      checkDuplicated();
    }
  }, [values.zipcode, values.street_number]);

  const reEvaluateAddress = () => {
    if (errors.street_number || errors.zipcode || errors.city || errors.country) {
      const isAddressValid = values.street_number && values.zipcode && values.city && values.country;
      if (isAddressValid) {
        setFieldTouched("street_number", false);
        setFieldTouched("zipcode", false);
        setFieldTouched("city", false);
        setFieldTouched("country", false);
        setFieldTouched("tour_id", false);
      }
    }
  };

  useEffect(() => {
    reEvaluateAddress();
  }, [values.street_number, values.zipcode, values.city, values.country, values.tour_id]);

  return (
    <Box height="100%">
      <Box display="flex" mb={4}>
        <Box flex={2}>
          <Typography className={classes._heading} variant="h4">
            {action === "ADD" ? t("New Customer") : t("Edit Customer")}
          </Typography>
        </Box>
        <Box flex={2} textAlign="right" position="relative">
          <Box position="absolute" right={0}>
            <FormCancelSaveButton
              disabled={!isValid || isSubmitting}
              onCancel={closeCustomerHandler}
              onSave={handleSubmit}
              ref={{ cancelRef: cancelButtonRef, saveRef: saveButtonRef }}
            />
          </Box>
        </Box>
      </Box>
      {!!similarCustomers?.length && (
        <Box my={2}>
          <Alert severity="error">
            {t("Similar Customer found")}:&nbsp;
            {similarCustomers.map((c, i) => (
              <>
                <Link to={PATHS.customers.view.replace(":id", c.id)} target="_blank">
                  {c.name}
                </Link>
                , &nbsp;
              </>
            ))}
          </Alert>
        </Box>
      )}
      <Typography className={classes._subheading} variant="h5">
        {t("Basic Data")}
      </Typography>
      <Grid container spacing={2}>
        {isAdminUser && (
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Autocomplete
              onBlur={handleBlur}
              name="supplier_id"
              label="Supplier"
              errors={errors}
              value={values.supplier_id}
              settings={{
                disableClearable: true,
                valueProp: "id",
                labelProp: "name",
              }}
              onChange={(selected) => {
                setFieldValue("supplier_id", selected.id);
              }}
              options={suppliers}
            />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Input
            label={t("Name")}
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            errors={errors}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Input
            label={t("Number")}
            name="number"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.number}
            errors={errors}
            required
          />
        </Grid>
      </Grid>
      <div style={{ display: "none" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Input
              label={t("Street")}
              name="street"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.street}
              errors={errors}
              required
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Input
              label={t("House No.")}
              name="street_number"
              onChange={handleChange}
              onBlur={(e) => {
                checkDuplicated();
              }}
              value={values.street_number}
              errors={errors}
              required
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={2} lg={1}>
            <Input
              label={t("Zipcode")}
              name="zipcode"
              onChange={customHandleChange}
              onBlur={(e) => {
                const value = e.target.value || "";
                const zipcode = zipcodes.find((zc) => zc.zipcode?.trim().toLowerCase() === value.trim().toLowerCase());

                if (values.tour_automatic_assigned) {
                  if (zipcode) {
                    setFieldValue("tour_id", zipcode.Tour.id);
                  } else {
                    const def = tourList.find((t) => t.is_default) || tourList[0];
                    if (def) {
                      setFieldValue("tour_id", def.id);
                    }
                  }
                }
                checkDuplicated();
              }}
              value={values.zipcode}
              errors={errors}
              required
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Input
              label={t("City")}
              name="city"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.city}
              errors={errors}
              required
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Autocomplete
              onBlur={handleBlur}
              name="country"
              label="Country"
              errors={errors}
              value={values.country}
              settings={{
                disableClearable: true,
                valueProp: "code",
                labelProp: "name",
              }}
              onChange={(selected) => {
                setFieldValue("country", selected.code);
              }}
              options={countries.map(({ code, name }) => ({
                code,
                name: i18n.language === "en" ? name : t(`country.${code}`),
              }))}
              required
              disabled
            />
          </Grid>
        </Grid>
      </div>
      {user?.permissions?.customers?.show_coordinates && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Input
                label={t("Latitude")}
                name="latitude"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.latitude}
                errors={errors}
                disabled
                required
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Input
                label={t("Longitude")}
                name="longitude"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.longitude}
                errors={errors}
                disabled
                required
              />
            </Grid>
          </Grid>
        </>
      )}
      {!user?.permissions?.customersHideLocationRelatedFields && !user?.permissions?.customers?.hide_location_fields && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {errors.address && <Alert severity="error">{errors.address}</Alert>}
              <SelectGeoCoordinates
                onChange={({
                  latitude,
                  longitude,
                  street,
                  street_number,
                  zipcode,
                  city,
                  country,
                  formatted_address,
                }) => {
                  const fields = {
                    latitude,
                    longitude,
                    street,
                    street_number,
                    zipcode,
                    city,
                    country,
                    formatted_address,
                  };

                  Object.entries(fields).forEach(([key, value]) => {
                    if (value) {
                      setFieldValue(key, value);
                    }
                  });

                  // Set zipcode
                  customHandleChange({
                    target: {
                      name: "zipcode",
                      value: zipcode || values.zipcode,
                    },
                  });
                }}
                latitude={values.latitude || 52.52321191756548}
                longitude={values.longitude || 13.405897492100648}
                initialInputValue={
                  values.id
                    ? values.formatted_address
                      ? values.formatted_address
                      : `${values.street} ${values.street_number}, ${values.zipcode}, ${values.city}, ${values.country}`
                    : ""
                }
              />
            </Grid>
          </Grid>
        </>
      )}
      {showLocationFields && (
        <>
          <Typography className={classes._subheading} variant="h5">
            {t("Contact")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Select
                label={t("Salutation")}
                name="contact_salutation"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.contact_salutation}
                errors={errors}
                options={["Mr", "Ms", "Dr"].map((o) => ({
                  label: t(o),
                  value: o,
                }))}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Input
                label={t("First Name")}
                name="contact_name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.contact_name}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Input
                label={t("Last Name")}
                name="contact_surname"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.contact_surname}
                errors={errors}
              />
            </Grid>
          </Grid>
        </>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Input
            label={t("Phone")}
            name="phone"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.phone}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Input
            label={t("E-Mail")}
            type="email"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <br />
          <div ref={!isAdminUser ? lastElementRef : null}>
            <Checkbox
              checked={values.email_notifications}
              value={values.email_notifications}
              name="email_notifications"
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              style={{ color: "#6F9CEB" }}
            />
            <Typography component="span" className="font-size-12">
              {t("Notify when Tour starts")}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Typography className={classes._subheading} variant="h5">
        {t("Tour")}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Select
            disabled={
              (!user?.permissions?.tours?.create && !user?.permissions?.zipcodes?.list) ||
              values.tour_automatic_assigned
            }
            label={t("Tour")}
            name="tour_id"
            onChange={(e) => {
              const { value } = e.target;
              return handleChange(e);
            }}
            onBlur={handleBlur}
            value={values.tour_id}
            errors={errors}
            options={tourList.map((o) => ({
              label: o.name,
              value: o.id,
              disabled: !o.active,
            }))}
            required
          />
        </Grid>
        {!usePermission("customers.hide_priority_customer") && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <div ref={!isAdminUser ? lastElementRef : null}>
              <Select
                label={t("Express Customer")}
                name="priority"
                onChange={(e) => {
                  const value = e.target.value === "true";
                  setFieldValue("priority", value);
                }}
                onBlur={handleBlur}
                value={values.priority ? "true" : "false"}
                errors={errors}
                options={[
                  { value: "true", label: t("Yes") },
                  { value: "false", label: t("No") },
                ]}
              />
            </div>
          </Grid>
        )}
        {isAdminUser && (
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <div ref={lastElementRef}>
              <Checkbox
                checked={values.tour_automatic_assigned}
                value={values.tour_automatic_assigned}
                name="tour_automatic_assigned"
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors}
                style={{ color: "#6F9CEB" }}
              />
              <Typography component="span" className="font-size-12">
                {t("Automatic tour")}
              </Typography>
            </div>
          </Grid>
        )}
      </Grid>
      {usePermission("customers?.deposit_agreement") && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Select
                label={t("Deposit agreement")}
                name="deposit_agreement"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.deposit_agreement}
                errors={errors}
                options={[
                  { value: "NONE", label: t("None") },
                  { value: "BRING_KEY", label: t("Bring Key") },
                  { value: "KEY_BOX", label: t("KeyBox") },
                ].map((o) => ({ label: o.label, value: o.value }))}
                required
              />
            </Grid>
            {values.deposit_agreement === "KEY_BOX" && (
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Input
                  label={t("Keybox Code")}
                  name="keybox_code"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.keybox_code}
                  errors={errors}
                  required={values.deposit_agreement === "KEY_BOX"}
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
      {usePermission("customers.deactivate") && (
        <Typography className={classes._subheading} variant="h5">
          {t("Status")}
        </Typography>
      )}

      <Grid container spacing={2}>
        {usePermission("customers.deactivate") && (
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Checkbox
              checked={values.active}
              value={values.active}
              name="active"
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              style={{ color: "#6F9CEB" }}
            />
            <Typography component="span" className="font-size-12">
              {t("Active")}
            </Typography>
          </Grid>
        )}
        {/* Duplicate marked customers are being treated as Archive */}
        {usePermission("customers.archive") && (
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Checkbox
              checked={values.duplicate}
              name="duplicate"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.priority ? "true" : "false"}
              errors={errors}
              options={[
                { value: "true", label: t("Yes") },
                { value: "false", label: t("No") },
              ]}
            />
            <Typography component="span" className="font-size-12">
              {t("Archive")}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
export default CustomerForm;
