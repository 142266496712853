import React, { useEffect, useRef, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import MaterialTable from "material-table";
import { useTranslation } from "react-i18next";
import {
  CURRENT_TOURS_COLUMNS_CURRENT_TOURS_TAB,
  CURRENT_TOURS_COLUMNS_CURRENT_TOURS_TAB_BOTH_KEY_PDF,
} from "constants/ui-constants";
import { getColumns, getLocalization } from "util/table-utils";
import { mapTableData } from "util/helpers";
import { PATHS } from "util/appConstants";
import { selectCurrent, selectRouteStatus, getCurrentRoutes, undoRoute } from "redux/slices/routeSlice";
import arrowpanel from "../../assets/img/arrow-panel.svg";
import TableExpansionPanel from "components/Routes/TableExpansionPanel";
import DarkLayout from "components/Shared/DarkLayout";
import Navbar from "components/Navbar";
import ToursNavbar from "components/Masterbar/TourBar";
import withConfirm from "components/dialogs/delete";
import { selectUser } from "redux/slices/userSlice";
import { sortingType } from "util/sortingType";

const CurrentTours = ({ confirm }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const tableRef = useRef();
  const { t } = useTranslation();
  const routes = useSelector(selectCurrent);
  const user = useSelector(selectUser);
  const [tabledata, settableData] = useState(routes);
  const loading = useSelector(selectRouteStatus);
  const myDivToFocus = useMemo(
    () =>
      Array(tabledata.length)
        .fill(0)
        .map((i) => React.createRef()),
    [tabledata.length]
  );

  useEffect(() => {
    if (!loading) {
      dispatch(getCurrentRoutes());
    }
  }, []);

  useEffect(() => {
    settableData(routes);
  }, [routes]);

  const scroll = (scrollOffset, rowData) => {
    myDivToFocus[rowData.tableData.id].current.scrollLeft += scrollOffset;
  };

  const markFavourite = (e, rowData) => {
    let favourites = localStorage.getItem("current-tours-favourites");
    favourites = favourites ? favourites.split(",") : [];

    if (rowData.is_favourite) {
      favourites = favourites.filter((f) => parseInt(f, 10) !== rowData.id);
    } else {
      favourites = favourites.concat([rowData.id]);
    }

    localStorage.setItem("current-tours-favourites", favourites.join(","));

    const newData = tableRef.current.state.data.map((item) => {
      if (item.id === rowData.id) {
        return {
          ...item,
          is_favourite: !item.is_favourite,
        };
      }

      return item;
    });

    settableData(newData.sort((a, b) => b.is_favourite - a.is_favourite));
  };

  const redirectView = (pathway, rowData) => {
    if (!pathway) {
      return history.push({ pathname: `${PATHS.tours.map}/${rowData.id}` });
    }

    history.push({
      pathname: `${PATHS.tours.map}/${rowData.id}/${pathway.id}`,
    });
  };

  const handleUndo = (id) => {
    confirm(
      () => {
        dispatch(undoRoute(id, t)).then(() => {
          dispatch(getCurrentRoutes());
        });
      },
      {
        description: t("Are you sure you want to undo this route?"),
      }
    );
  };
  const permissionBasedColumns = (user) => {
    const type = sortingType(user);
    if (user?.permissions?.routes?.delivery_app_doc) {
      return getColumns(
        CURRENT_TOURS_COLUMNS_CURRENT_TOURS_TAB_BOTH_KEY_PDF(
          tableRef,
          markFavourite,
          redirectView,
          t,
          handleUndo,
          type,
          user
        ),
        t
      );
    }
    return getColumns(
      CURRENT_TOURS_COLUMNS_CURRENT_TOURS_TAB(tableRef, markFavourite, redirectView, t, handleUndo, type),
      t
    );
  };

  return (
    <>
      <Navbar />
      <ToursNavbar />
      <DarkLayout doublebar loading={loading}>
        <MaterialTable
          tableRef={tableRef}
          data={mapTableData(tabledata)}
          localization={getLocalization(t)}
          columns={permissionBasedColumns(user)}
          options={{
            pageSize: 50,
            pageSizeOptions: [50, 100],
            sorting: false,
            paging: false,
            detailPanelColumnAlignment: "right",
            header: false,
            searchFieldAlignment: "left",
            showTitle: false,
            cellStyle: {
              color: "white",
              border: "none",
              font: "normal normal normal 18px/24px Roboto",
            },
            rowStyle: (rowData) => {
              if (rowData.tableData.id % 2 === 0) {
                return { backgroundColor: " #1F1F1F " };
              } else {
                return { backgroundColor: "#525252" };
              }
            },
          }}
          detailPanel={[
            {
              icon: () => (
                <img
                  alt="icon"
                  src={arrowpanel}
                  style={{
                    width: "22px",
                    height: " 10px",
                    transform: " rotate(180deg)",
                  }}
                />
              ),
              openIcon: () => (
                <img
                  alt="icon"
                  src={arrowpanel}
                  style={{
                    width: "22px",
                    height: " 10px",
                  }}
                />
              ),
              render: (rowData) => {
                return (
                  <TableExpansionPanel
                    {...{
                      rowData,
                      scroll,
                      redirectView,
                      myDivToFocus,
                    }}
                  />
                );
              },
            },
          ]}
        />
      </DarkLayout>
    </>
  );
};
export default withConfirm(CurrentTours);
