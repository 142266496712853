import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { PATHS } from "util/appConstants";
import {
  selectOrder,
  selectOrderStatus,
  getOrder,
  editOrder,
} from "redux/slices/orderSlice";
import { getCustomers, selectCustomers } from "redux/slices/customerSlice";
import OrderForm from "components/Orders/form";
import Navbar from "components/Navbar";
import LightLayout from "components/Shared/LightLayout";
import { selectUser } from "redux/slices/userSlice";
import { getRoutesForCurrentTours } from "redux/slices/routeSlice";

const currentAction = "EDIT";

const EditOrder = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const loading = useSelector(selectOrderStatus);
  const order = useSelector(selectOrder);
  const customers = useSelector(selectCustomers);

  useEffect(() => {
    if (id) {
      dispatch(getOrder(id));
    }
  }, []);

  // TODO: Elegantly handle getting all customers for lager
  useEffect(() => {
    if (!customers.length) {
      if (user?.permissions?.customers?.admin) {
        dispatch(
          getCustomers(true, { where: { duplicate: false, active: true } })
        );
      }
      // In case of lager, get all customers as lager can contain inactive/archive customers data as well
      else if (user?.permissions?.orders?.list_supplier) {
        dispatch(
          getCustomers(true, {
            where: {
              supplier_group_id: user?.supplier_group_id,
            },
          })
        );
      } else {
        dispatch(
          getCustomers(true, {
            where: {
              supplier_id: user?.supplier_id,
              duplicate: false,
              active: true,
            },
          })
        );
      }
      dispatch(getRoutesForCurrentTours());
    }
  }, [dispatch, customers, user]);

  const onSubmit = async (payload) => {
    await dispatch(editOrder(id, payload));
    history.push(PATHS.orders.root);
  };

  return (
    <>
      <Navbar />
      <LightLayout loading={loading || !order}>
        <OrderForm
          initialValues={order}
          onSubmit={onSubmit}
          action={currentAction}
          customerList={customers}
        />
      </LightLayout>
    </>
  );
};
export default EditOrder;
