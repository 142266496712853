import { useRef, useEffect } from 'react';

export function useDidUpdateEffect(
  effect,
  deps,
) {
  const isMountingRef = useRef(false);

  useEffect(() => {
    isMountingRef.current = true;
  }, []);

  useEffect(() => {
    if (!isMountingRef.current) {
      return effect();
    } else {
      isMountingRef.current = false;
    }
  }, deps);
}
