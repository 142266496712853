import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { PATHS } from "util/appConstants";
import Navbar from "components/Navbar";
import LightLayout from "components/Shared/LightLayout";
import SupplierLocationForm from "components/SupplierLocations/form";
import AdminBar from "components/Masterbar/AdminBar";
import { addSupplierLocation } from "redux/slices/supplierLocationsSlice";
import { getSuppliers, selectSuppliers, selectSuppliersStatus } from "redux/slices/supplierSlice";

const currentAction = "ADD";
const AddSupplierLocation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(selectSuppliersStatus);
  const suppliers = useSelector(selectSuppliers);

  useEffect(() => {
    if (!suppliers.length && !loading) {
      dispatch(getSuppliers());
    }
  }, [dispatch, suppliers]);

  const onSubmit = async (payload) => {
    await dispatch(addSupplierLocation(payload)).then((res) =>
      res !== undefined ? history.push(PATHS.admin.supplierLocations) : ""
    );
  };

  return (
    <>
      <Navbar />
      <AdminBar />
      <LightLayout doublebar>
        <SupplierLocationForm action={currentAction} onSubmit={onSubmit} suppliers={suppliers} />
      </LightLayout>
    </>
  );
};

export default AddSupplierLocation;
