import React, { useEffect } from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Navbar from "components/Navbar";
import DarkLayout from "components/Shared/DarkLayout";
import ToursNavbar from "components/Masterbar/TourBar";
import { DatePicker } from "components/Shared/mui-formik-inputs";
import { getStopsHistory } from "redux/slices/tourSlice";
import { useDispatch, useSelector } from "react-redux";
import { getPastOrders } from "redux/slices/tourSlice";
import { selectUser } from "redux/slices/userSlice";

const locales = {
  "en-us": "en",
  en: "en",
  de: "de",
};

const ExportRoute = () => {
  const [dates, setDates] = React.useState({
    from: moment().subtract(3, "months").startOf("day"),
    to: moment().endOf("day"),
  });
  const dispatch = useDispatch();

  const { i18n, t } = useTranslation();
  const downloadStopsHistory = (from, to) => {
    const language = i18n.language === "de" ? "de" : "en";
    dispatch(getStopsHistory(from, to, language));
  };

  const downloadPastOrders = (from, to) => {
    const language = i18n.language === "de" ? "de" : "en";
    dispatch(getPastOrders(from, to, language, t));
  };
  const user = useSelector(selectUser);

  return (
    <>
      <Navbar />
      <ToursNavbar />
      <DarkLayout doublebar>
        <MuiPickersUtilsProvider
          utils={MomentUtils}
          locale={locales[i18n.language.toLowerCase()]}
        >
          <Box display="flex">
            <Box flex={1} mr={2}>
              <DatePicker
                onChange={(date) => {
                  if (date && date.isValid()) {
                    setDates({ ...dates, from: date });
                  }

                  if (date === null) {
                    setDates({ ...dates, from: null });
                  }
                }}
                name="from"
                value={dates.from}
                errors={{}}
                label="From"
                required
                className="datepicker-label-white"
              />
            </Box>
            <Box flex={1} mr={2}>
              <DatePicker
                onChange={(date) => {
                  if (date && date.isValid()) {
                    setDates({ ...dates, to: date });
                  }

                  if (date === null) {
                    setDates({ ...dates, to: null });
                  }
                }}
                name="to"
                value={dates.to}
                errors={{}}
                label="To"
                required
                className="datepicker-label-white"
              />
            </Box>
            {user?.permissions?.routes?.export_tour_history && (
              <Box flex={1} mr={2}>
                <Box pt={2}>
                  {!!dates.from && !!dates.to && (
                    <Button
                      component="a"
                      variant="contained"
                      color="primary"
                      download
                      href={`${
                        process.env.REACT_APP_API
                      }routes/export/excel/${dates.from
                        .startOf("day")
                        .format("YYYY-MM-DD HH:mm:ss")}/${dates.to
                        .endOf("day")
                        .format(
                          "YYYY-MM-DD HH:mm:ss"
                        )}?taira=${localStorage.getItem("token")}`}
                    >
                      {t("Download Tour History")}
                    </Button>
                  )}
                </Box>
              </Box>
            )}
            {user?.permissions?.routes?.export_stops_analysis && (
              <Box flex={1} mr={2}>
                <Box pt={2}>
                  {!!dates.from && !!dates.to && (
                    <Button
                      component="a"
                      variant="contained"
                      color="primary"
                      download
                      onClick={() =>
                        downloadStopsHistory(
                          dates.from
                            .startOf("day")
                            .format("YYYY-MM-DD HH:mm:ss"),
                          dates.to.endOf("day").format("YYYY-MM-DD HH:mm:ss")
                        )
                      }
                    >
                      {t("Download Stops Analysis")}
                    </Button>
                  )}
                </Box>
              </Box>
            )}
            {user?.permissions?.routes?.export_past_orders && (
              <Box flex={1} mr={2}>
                <Box pt={2}>
                  {!!dates.from && !!dates.to && (
                    <Button
                      component="a"
                      variant="contained"
                      color="primary"
                      download
                      onClick={() =>
                        downloadPastOrders(
                          dates.from
                            .startOf("day")
                            .format("YYYY-MM-DD HH:mm:ss"),
                          dates.to.endOf("day").format("YYYY-MM-DD HH:mm:ss")
                        )
                      }
                    >
                      {t("Download Past Orders")}
                    </Button>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </MuiPickersUtilsProvider>
      </DarkLayout>
    </>
  );
};

export default ExportRoute;
