import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { PATHS } from 'util/appConstants';
import { addZipcode } from 'redux/slices/zipcodeSlice';
import { getTours, selectTours, selectTourStatus } from 'redux/slices/tourSlice';
import ZipcodesForm from 'components/Zipcodes/form';
import Navbar from 'components/Navbar';
import CustomersNavbar from 'components/Masterbar/CustomersBar';
import LightLayout from 'components/Shared/LightLayout';
import {useTranslation} from "react-i18next";

const currentAction = "ADD";
const ZipcodeAdd = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(selectTourStatus);
  const tours = useSelector(selectTours);

  useEffect(() => {
    if (!tours.length && !loading) {
      dispatch(getTours());
    }
  }, [dispatch, tours]);

  const onSubmit = async (payload) => {
    await dispatch(addZipcode(payload, t)).then((res) =>
      res !== undefined ? history.push(PATHS.zipcodes.root) : ""
    );
  };

  return (
    <>
      <Navbar />
      <CustomersNavbar />
      <LightLayout doublebar>
        <ZipcodesForm
          action={currentAction}
          onSubmit={onSubmit}
          tourList={tours}
        />
      </LightLayout>
    </>
  );
};

export default ZipcodeAdd;
