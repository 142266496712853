import { createSlice, createSelector } from "@reduxjs/toolkit";
import { coreApi } from "api/core";
import { setShowMessage } from "redux/slices/uiSlice";

const baseUrl = "/handovers";
const initialState = {
  handovers: [],
  timestamp: null,
  handover: null,
  loading: false,
};

const handoverSlice = createSlice({
  name: "handovers",
  initialState,
  reducers: {
    setHandover: (state, action) => {
      state.handover = action.payload;
    },
    setHandovers: (state, action) => {
      state.handovers = action.payload;
      state.timestamp = +new Date();
    },
    setHandoverLoading: (state) => {
      state.loading = true;
    },
    setHandoverReady: (state) => {
      state.loading = false;
    },
    clearHandovers: (state) => {
      state.handovers = [];
      state.handover = null;
      state.timestamp = null;
    },
  },
});

export const { setHandover, setHandovers, setHandoverLoading, setHandoverReady, clearHandovers } =
  handoverSlice.actions;
export default handoverSlice.reducer;

export const getHandover = (id) => async (dispatch) => {
  const url = baseUrl + `/${id}`;
  dispatch(setHandoverLoading());

  try {
    const res = await coreApi.fetch(url);
    dispatch(setHandover(res));
  } catch (err) {
    console.error(err);
  } finally {
    dispatch(setHandoverReady());
  }
};

export const getHandovers = () => async (dispatch) => {
  dispatch(setHandoverLoading());

  try {
    const handovers = await coreApi.fetch(baseUrl);

    dispatch(setHandovers(handovers));
  } catch (err) {
    console.error(err);
  } finally {
    dispatch(setHandoverReady());
  }
};

export const addHandover = (payload, t) => async (dispatch) => {
  dispatch(setHandoverLoading());

  try {
    const handover = await coreApi.post(baseUrl, payload);
    dispatch(clearHandovers());
    dispatch(
      setShowMessage({
        description: t("Handover protocol created for the selected orders"),
        type: "success",
      })
    );

    return handover;
  } catch (err) {
    dispatch(
      setShowMessage({
        description: "There was an error creating the handover protocol for the selected orders",
        type: "error",
      })
    );
    console.error(err);
  } finally {
    dispatch(setHandoverReady());
  }
};

export const deleteHandover = (id, t) => async (dispatch) => {
  const url = baseUrl + `/${id}`;
  dispatch(setHandoverLoading());

  try {
    const res = await coreApi.delete(url);

    dispatch(
      setShowMessage({
        description: t("Handover deleted successfully"),
        type: "success",
      })
    );

    dispatch(getHandovers());

    return res;
  } catch (err) {
    console.error(err);
  } finally {
    dispatch(setHandoverReady());
  }
};

export const complete = (id, t) => async (dispatch) => {
  const url = baseUrl + `/${id}/complete`;
  dispatch(setHandoverLoading());

  try {
    const res = await coreApi.put(url);

    dispatch(
      setShowMessage({
        description: t("Handover marked as complete. Orders available to process"),
        type: "success",
      })
    );

    dispatch(getHandovers());

    return res;
  } catch (err) {
    console.error(err);
  } finally {
    dispatch(setHandoverReady());
  }
};

const handoverSelector = ({ handovers }) => handovers.handover;
const handoversSelector = ({ handovers }) => handovers.handovers;
const handoverStatusSelector = ({ handovers }) => handovers.loading;

export const selectHandover = createSelector(handoverSelector, (handover) => handover);
export const selectHandovers = createSelector(handoversSelector, (handovers) => handovers);
export const selectHandoverStatus = createSelector(handoverStatusSelector, (loading) => loading);
export const selectHandoversTimestamp = createSelector(
  ({ handovers }) => handovers.timestamp,
  (o) => o
);
