import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import { Typography, Box } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import clsx from "clsx";
import { PATHS } from "util/appConstants";
import Navbar from "components/Navbar";
import DarkLayout from "components/Shared/DarkLayout";
import AdminBar from "components/Masterbar/AdminBar";
import { getSuppliers, selectSuppliers } from "redux/slices/supplierSlice";
import { getSupplierGroup, selectSupplierGroup, selectSupplierGroupsStatus } from "redux/slices/supplierGroups";
import { selectUser } from "redux/slices/userSlice";

const useStyles = makeStyles({
  _heading: {
    color: "#F5F5F5",
    font: "normal normal normal 28px/40px Questrial",
  },
  _edit: {
    color: "#6F9CEB",
    width: "22px",
    height: "22px",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      transform: "scale(1.3)",
    },
    "&:hover + span": {
      display: "block",
    },
  },
  _editbox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  _infoheading: {
    color: "#F5F5F5",
    font: "normal normal medium 22px/32px Roboto",
    marginTop: "44px",
  },
  _head: {
    color: "#F5F5F5",
    opacity: "0.6",
    font: "normal normal normal 12px/24px Roboto",
    marginRight: "32px",
  },
  _basicdetailhead: {
    display: "flex",
    marginTop: "41px",
  },
  _basicdetail: {
    display: "flex",
  },
  _detail: {
    color: "#F5F5F5",
    marginRight: "32px",
    font: "normal normal normal 18px/24px Roboto",
  },
  _name: {
    // width: '228px',
    letterSpacing: "0px",
  },
  _width11: {
    // width: '11px'
  },
  _margintop80: {
    marginTop: "80px",
  },
  _adressbox: {
    marginTop: "24px",
  },
  _buttonbox: {
    display: "flex",
    margin: "0 16px",
  },
  _edittext: {
    width: "24px",
    height: "16px",
    color: "#6F9CEB",
    font: "normal normal normal 14px / 20px Roboto",
    padding: "4px 8px",
    display: "none",
    position: "absolute",
    marginLeft: "20px",
    transition: "all 0.3s ease-in-out",
  },
});

const AccountDetails = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const loading = useSelector(selectSupplierGroupsStatus);
  const suppliers = useSelector(selectSuppliers);
  const supplierGroup = useSelector(selectSupplierGroup);
  const user = useSelector(selectUser);

  useEffect(() => {
    if (!suppliers.length && !loading) {
      dispatch(getSuppliers());
    }
  }, [dispatch, suppliers]);

  useEffect(() => {
    if (!supplierGroup.length && !loading) {
      dispatch(getSupplierGroup());
    }
  }, [dispatch, supplierGroup]);

  const editHandler = () => {
    history.push(PATHS.admin.accountDetailsEdit.replace(":id", user?.supplier_group_id));
  };

  return (
    <>
      <Navbar />
      <AdminBar />
      <DarkLayout doublebar loading={loading || !suppliers}>
        <div className={classes._editbox}>
          <Typography className={classes._heading} variant="h4">
            {supplierGroup?.name}
          </Typography>
          <Box className={classes._buttonbox} component="div">
            <EditIcon onClick={editHandler} className={classes._edit} />
            <Typography component="span" className={clsx(classes._edittext, "edittag")}>
              {t("Edit")}
            </Typography>
          </Box>
        </div>
        <Typography className={classes._infoheading} variant="h5">
          {t("Basic Data")}
        </Typography>
        <div className={classes._basicdetailhead}>
          <div>
            <Typography className={clsx(classes._head, classes._width11)} variant="h6">
              {t("Suppliers")}
            </Typography>
            <Typography className={clsx(classes._detail, classes._width11)} variant="h6">
              {suppliers
                ?.map((supplier) => supplier.name)
                .sort((a, b) => a.localeCompare(b))
                .join(", ")}
            </Typography>
          </div>
        </div>
        <div className={classes._basicdetailhead}>
          <div>
            <Typography className={clsx(classes._head, classes._width11)} variant="h6">
              {t("Logo")}
            </Typography>
            <a
              href={supplierGroup?.image_url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#6F9CEB", textDecoration: "underline" }} // set color and underline style
            >
              <Typography className={classes._detail} variant="h6" style={{ color: "#6F9CEB" }}>
                {supplierGroup?.filename}
              </Typography>
            </a>
          </div>
        </div>
      </DarkLayout>
    </>
  );
};

export default AccountDetails;
