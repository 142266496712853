import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { Button, IconButton } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import DocumentIcon from "@material-ui/icons/Description";
import DeleteSharpIcon from "@material-ui/icons/DeleteSharp";
import EmptyCircleIcon from "@material-ui/icons/RadioButtonUnchecked";
import FilledCircleIcon from "@material-ui/icons/RadioButtonChecked";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import moment from "moment";
import { TOURS_TABLE_COLUMNS } from "constants/ui-constants";
import { getColumns, getActions, getLocalization } from "util/table-utils";
import { mapTableData } from "util/helpers";
import { PATHS } from "util/appConstants";
import {
  complete,
  getHandovers,
  deleteHandover,
  selectHandoverStatus,
  selectHandovers,
  selectHandoversTimestamp,
} from "redux/slices/handoverSlice";
import withConfirm from "components/dialogs/delete";
import Navbar from "components/Navbar";
import CustomersNavbar from "components/Masterbar/CustomersBar";
import DarkLayout from "components/Shared/DarkLayout";
import { selectUser } from "redux/slices/userSlice";

const useStyles = makeStyles({
  _filtericon: {
    color: "#525252",
    fontSize: "12px",
  },
});

const tableTitle = "PROTOCOLS";

const HandoversList = ({ confirm }) => {
  const { t } = useTranslation("common");
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(selectHandoverStatus);
  const handovers = useSelector(selectHandovers);
  const timestamp = useSelector(selectHandoversTimestamp);
  const user = useSelector(selectUser);

  useEffect(() => {
    if (!loading && !timestamp) {
      dispatch(getHandovers());
    }
  }, [dispatch, handovers]);

  const callbackOnDelete = (rowData) => {
    confirm(() => dispatch(deleteHandover(rowData.id, t)), {
      description: t(
        "Delete the handover protocol and release the orders? Orders will be available in the order's list again."
      ),
    });
  };

  const callbackOnDeleteCompleted = (rowData) => {
    confirm(() => dispatch(deleteHandover(rowData.id, t)), {
      description: t(
        "Delete the handover protocol and release the orders? Orders will not be available to edit again."
      ),
    });
  };

  const onComplete = (rowData) => {
    confirm(() => dispatch(complete(rowData.id, t)), {
      description: t(
        "Mark handover as complete? Orders will become available to continue processing"
      ),
    });
  };

  const pendingHandovers = handovers.filter((h) => h.status === "PENDING");
  const completedHandovers = handovers.filter((h) => h.status === "COMPLETE");

  return (
    <>
      <Navbar />
      <DarkLayout doublebar loading={loading}>
        <div className="custom-table-styles">
          <MaterialTable
            data={mapTableData(pendingHandovers)}
            title={t("Pending handovers")}
            columns={[
              {
                title: "ID",
                render: (rowData) => (
                  <span style={{ fontSize: "15px", fontWeight: 500 }}>
                    {moment(rowData.created_at).format("DD.MM.YYYY HH:mm")}
                  </span>
                ),
              },
              {
                title: "PDF",
                render: (rowData) => {
                  return (
                    <IconButton
                      component="a"
                      href={`${process.env.REACT_APP_API}handovers/${
                        rowData.id
                      }/document?taira=${localStorage.getItem("token")}`}
                      target="_blank"
                      color="primary"
                    >
                      <DocumentIcon />
                    </IconButton>
                  );
                },
              },
              {
                title: "Delete",
                render: (rowData) => {
                  if (
                    moment(rowData.created_at)
                      .add(24, "hours")
                      .isAfter(moment())
                  ) {
                    return (
                      <IconButton
                        color="primary"
                        onClick={() => callbackOnDelete(rowData)}
                      >
                        <DeleteSharpIcon />
                      </IconButton>
                    );
                  }

                  return null;
                },
              },
              {
                title: "Complete",
                render: (rowData) => {
                  if (
                    moment(rowData.created_at).add(2, "weeks").isAfter(moment())
                  ) {
                    return (
                      <Button
                        color="primary"
                        onClick={() => onComplete(rowData)}
                      >
                        {t("Mark Complete")}
                      </Button>
                    );
                  }

                  return null;
                },
              },
            ]}
            localization={getLocalization(t)}
            options={{
              pageSize: 50,
              pageSizeOptions: [50, 100],
              detailPanelColumnAlignment: "right",
              paging: false,
              actionsColumnIndex: -1,
              search: false,
              headerStyle: {
                backgroundColor: "#121212",
                color: "white",
                borderBottom: "1px solid #525252",
                font: "normal normal normal 12px/24px Roboto",
                fontWeight: "bold",
              },
              cellStyle: {
                color: "white",
                border: "none",
                font: "normal normal normal 12px/24px Roboto",
              },
              // showTitle: false,
              header: false,
              showTextRowsSelected: false,
              showSelectAllCheckbox: false,
              rowStyle: (rowData) => {
                if (rowData.tableData.id % 2 === 0) {
                  return { backgroundColor: " #1F1F1F ", height: "71px" };
                } else {
                  return { backgroundColor: "#525252", height: "71px" };
                }
              },
            }}
          />
        </div>

        {completedHandovers?.length && (
          <div className="custom-table-styles" style={{ marginTop: "32px" }}>
            <MaterialTable
              data={mapTableData(completedHandovers)}
              title={t("Completed handovers")}
              columns={[
                {
                  title: "ID",
                  render: (rowData) => (
                    <span style={{ fontSize: "15px", fontWeight: 500 }}>
                      {moment(rowData.created_at).format("DD.MM.YYYY HH:mm")}
                    </span>
                  ),
                },
                {
                  title: "PDF",
                  render: (rowData) => {
                    return (
                      <IconButton
                        component="a"
                        href={`${process.env.REACT_APP_API}handovers/${
                          rowData.id
                        }/document?taira=${localStorage.getItem("token")}`}
                        target="_blank"
                        color="primary"
                      >
                        <DocumentIcon />
                      </IconButton>
                    );
                  },
                },
                {
                  title: "",
                  render: (rowData) => {
                    if (
                      moment(rowData.created_at)
                        .add(2, "weeks")
                        .isAfter(moment())
                    ) {
                      return (
                        <Button
                          style={{ visibility: "hidden" }}
                          color="primary"
                          onClick={() => onComplete(rowData)}
                        >
                          {t("Mark Complete")}
                        </Button>
                      );
                    }

                    return null;
                  },
                },
              ]}
              localization={getLocalization(t)}
              options={{
                pageSize: 50,
                pageSizeOptions: [50, 100],
                detailPanelColumnAlignment: "right",
                paging: false,
                actionsColumnIndex: -1,
                search: false,
                headerStyle: {
                  backgroundColor: "#121212",
                  color: "white",
                  borderBottom: "1px solid #525252",
                  font: "normal normal normal 12px/24px Roboto",
                  fontWeight: "bold",
                },
                cellStyle: {
                  color: "white",
                  border: "none",
                  font: "normal normal normal 12px/24px Roboto",
                },
                // showTitle: false,
                header: false,
                showTextRowsSelected: false,
                showSelectAllCheckbox: false,
                rowStyle: (rowData) => {
                  if (rowData.tableData.id % 2 === 0) {
                    return { backgroundColor: " #1F1F1F ", height: "71px" };
                  } else {
                    return { backgroundColor: "#525252", height: "71px" };
                  }
                },
              }}
            />
          </div>
        )}
      </DarkLayout>
    </>
  );
};

export default withConfirm(HandoversList);
