import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Box } from "@material-ui/core";
import * as pick from "lodash/pick";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useHistory, useParams } from "react-router-dom";
import {
  Input,
  Select,
} from "components/Shared/mui-formik-inputs";
import { ZipcodeSchema } from "constants/validation-schemas";
import { ZipcodeFormAllowedFields } from "constants/forms-submit-allowed-fields";
import { PATHS } from "util/appConstants";
import FormCancelSaveButton from "components/Shared/FormCancelSaveButtons";
import { selectUser } from "redux/slices/userSlice";

const useStyles = makeStyles({
  _heading: {
    font: "normal normal normal 28px/40px Questrial",
    color: "#121212",
  },
  _icons: {
    color: "#ADADAD",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    margin: "0 16px 0 0px",
    fontSize: "35px",
  },
  _save: {
    "&:hover": {
      transform: "scale(1.3)",
      color: "#6F9CEB",
    },
    "&:hover + span": {
      display: "block",
    },
  },
  _close: {
    "&:hover": {
      transform: "scale(1.3)",
      color: "#525252",
    },
    "&:hover + span": {
      display: "block",
    },
  },
  _subheading: {
    font: "normal normal 500 22px/32px Roboto",
    color: " #121212",
    marginTop: "44px",
    marginBottom: "40px",
  },
  _dflex: {
    display: "flex",
    alignItems: "center",
  },
});
const CustomerForm = ({ initialValues, onSubmit, action, tourList }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { id } = useParams();

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: ZipcodeSchema,
    initialValues: {
      zipcode: '',
      tour_id: null,
      ...initialValues,
    },
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        await onSubmit(pick(values, ZipcodeFormAllowedFields));
      } catch (err) {
        if (err?.response?.data?.errors) {
          setErrors(err?.response?.data?.errors);
        }
        setSubmitting(false);
      }
    },
  });

  const {
    values,
    handleChange,
    setFieldValue,
    errors,
    handleSubmit,
    submitCount,
    isValid,
    isSubmitting,
  } = formik;
  let { handleBlur } = formik;

  const handleNumericChange = (event) => {
    const { name, value } = event.target;
    const numericValue = value.replace(/[^0-9]/g, '');
    event.target.value = numericValue;
    handleChange(event);
  };
  

  if (!submitCount) {
    handleBlur = null;
  }

  const toursOptions = tourList.map((o) => ({ label: o.name, value: o.id, disabled: !o.active }));

  const cancelButtonRef = useRef(null);
  const saveButtonRef = useRef(null);

  const lastElementRef = useRef(null);

  useEffect(() => {
    const handleTabPress = (e) => {
      if (e.key === "Tab") {
        if (lastElementRef.current && lastElementRef.current.contains(document.activeElement)) {
          e.preventDefault();
          if (cancelButtonRef.current) {
            cancelButtonRef.current.focus();
          }
        }
      }
    };

    document.addEventListener("keydown", handleTabPress);
    return () => {
      document.removeEventListener("keydown", handleTabPress);
    };
  }, []);

  return (
    <Box height="100%">
      <Box display="flex" mb={4}>
        <Box flex={2}>
          <Typography className={classes._heading} variant="h4">
            {action === "ADD" ? t("New Zipcode") : t("Edit Zipcode")}
          </Typography>
        </Box>
        <Box flex={2} textAlign="right" position="relative">
          <Box position="absolute" right={0}>
            <FormCancelSaveButton
              disabled={!isValid || isSubmitting}
              onCancel={() => history.push(PATHS.zipcodes.root)}
              onSave={handleSubmit}
              ref={{ cancelRef: cancelButtonRef, saveRef: saveButtonRef }}
            />
          </Box>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Input
            label={t("Zipcode")}
            name="zipcode"
            onChange={handleNumericChange}
            onBlur={handleBlur}
            value={values.zipcode}
            errors={errors}
            inputProps={{
              maxLength: 5
            }}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          {/*<Select*/}
          {/*  label={t("Tour")}*/}
          {/*  name="tour_id"*/}
          {/*  onChange={(e) => {*/}
          {/*    return handleChange(e);*/}
          {/*  }}*/}
          {/*  onBlur={handleBlur}*/}
          {/*  value={values.tour_id}*/}
          {/*  errors={errors}*/}
          {/*  options={toursOptions}*/}
          {/*  required*/}
          {/*/>*/}
          {
            !!toursOptions.length &&
            <div ref={lastElementRef}>
              <Autocomplete
                  id="tours-autocomplete"
                  options={toursOptions}
                  getOptionLabel={(option) => option.label}
                  style={{ width: 300 }}
                  onBlur={handleBlur}
                  value={toursOptions.find(t => t.value === values.tour_id)}
                  onChange={(evt, opt) => {
                    handleChange({
                      target: {
                        name: 'tour_id',
                        value: opt.value
                      }
                    })
                  }}
                  required
                  disableClearable
                  renderInput={(params) => <TextField {...params} label={t("Tour")} required variant="filled" margin={"dense"} />}
                  size="small"
              />
            </div>
          }
        </Grid>
      </Grid>
    </Box>
  );
};
export default CustomerForm;
