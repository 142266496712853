import React, { useEffect, useRef, useState } from "react";
import { Typography, Box, Grid, FormControl, Link, IconButton, FormHelperText } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { PATHS } from "util/appConstants";
import FormCancelSaveButton from "components/Shared/FormCancelSaveButtons";
import DeleteIcon from "@material-ui/icons/Delete";

const locales = {
  "en-us": "en",
  en: "en",
  de: "de",
};

const useStyles = makeStyles({
  _heading: {
    font: "normal normal normal 28px/40px Questrial",
    color: "#121212",
  },
  _icons: {
    color: "#ADADAD",
    fontSize: "35px",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    margin: "0 16px 0 0px",
  },
  _save: {
    "&:hover": {
      transform: "scale(1.3)",
      color: "#6F9CEB",
    },
  },
  _close: {
    "&:hover": {
      transform: "scale(1.3)",
      color: "#525252",
    },
  },
  _subheading: {
    font: "normal normal 500 22px/32px Roboto",
    color: " #121212",
    marginTop: "44px",
  },
});
const AccountDetailsForm = ({ initialValues, onSubmit }) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [selectedFile, setSelectedFile] = useState(null);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    initialValues: {
      filename: initialValues?.filename ?? null,
      image_url: initialValues?.image_url ?? "#",
      ...initialValues,
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const formData = new FormData();
        if (selectedFile) {
          formData.append("filename", selectedFile);
        } else {
          formData.append("keepExistingFile", "true");
          formData.append("filename", initialValues.filename);
        }
        await onSubmit(formData);
      } catch (err) {
        setSubmitting(false);
      }
    },
  });
  const { values, setFieldTouched, errors, handleSubmit, setFieldValue, isValid, isSubmitting } = formik;

  const closeHandler = () => {
    history.push(PATHS.admin.accountDetails);
  };

  const cancelButtonRef = useRef(null);
  const saveButtonRef = useRef(null);

  const lastElementRef = useRef(null);
  useEffect(() => {
    const handleTabPress = (e) => {
      if (e.key === "Tab") {
        if (lastElementRef.current && lastElementRef.current.contains(document.activeElement)) {
          e.preventDefault();
          if (cancelButtonRef.current) {
            window.scrollTo({ top: 0, behavior: "smooth" });
            cancelButtonRef.current.focus();
          }
        }
      }
    };

    document.addEventListener("keydown", handleTabPress);
    return () => {
      document.removeEventListener("keydown", handleTabPress);
    };
  }, []);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      setFieldValue("filename", file);
      setFieldTouched("filename", true, false);
      setTimeout(() => {
        formik.validateField("filename");
      }, 100);
    }
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
    setFieldValue("filename", null);
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale={locales[i18n.language.toLowerCase()]}>
      <Box height="100%">
        <Box display="flex" mb={4}>
          <Box flex={2}>
            <Typography className={classes._heading} variant="h4">
              {t("Edit Account Details")}
            </Typography>
          </Box>
          <Box flex={2} textAlign="right" position="relative">
            <Box position="absolute" right={0}>
              <FormCancelSaveButton
                disabled={!isValid || isSubmitting}
                onCancel={closeHandler}
                onSave={handleSubmit}
                ref={{ cancelRef: cancelButtonRef, saveRef: saveButtonRef }}
              />
            </Box>
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <FormControl fullWidth error={Boolean(errors.filename)} style={{ marginTop: "20px" }}>
              <Box display="flex" alignItems="center">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  style={{
                    padding: "16px",
                    border: "1px dashed #c4c4c4",
                    borderRadius: "8px",
                    cursor: "pointer",
                    textAlign: "center",
                    marginRight: "10px",
                  }}
                >
                  <input
                    type="file"
                    id="file-input"
                    onChange={handleFileChange}
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <label htmlFor="file-input" style={{ cursor: "pointer", color: "#000" }}>
                    <Typography variant="body1" color="textSecondary">
                      {t("Upload Logo")}
                    </Typography>
                  </label>
                </Box>

                {/* Display the filename link if available */}
                {(selectedFile || values?.filename) && (
                  <Box display="flex" alignItems="center" maxWidth="150px" overflow="hidden">
                    <Link
                      href={selectedFile ? URL.createObjectURL(selectedFile) : values.image_url}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        color: "black",
                        marginRight: "10px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {selectedFile ? selectedFile.name : values.filename}
                    </Link>
                    <IconButton size="small" onClick={handleFileRemove} aria-label="delete file">
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}
              </Box>
              {errors.filename && <FormHelperText>{t(errors.filename)}</FormHelperText>}
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </MuiPickersUtilsProvider>
  );
};
export default AccountDetailsForm;
