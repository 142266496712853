import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { PATHS } from "util/appConstants";
import Navbar from "components/Navbar";
import LightLayout from "components/Shared/LightLayout";
import AdminBar from "components/Masterbar/AdminBar";
import { useParams } from "react-router-dom";
import AccountDetailsForm from "components/AccountDetails/form";
import {
  getSupplierGroup,
  selectSupplierGroup,
  selectSupplierGroupsStatus,
  updateSupplierGroup,
} from "redux/slices/supplierGroups";

const EditAccountDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(selectSupplierGroupsStatus);
  const supplierGroup = useSelector(selectSupplierGroup);
  const { id } = useParams();

  useEffect(() => {
    if (!supplierGroup.length && !loading) {
      dispatch(getSupplierGroup());
    }
  }, [dispatch, supplierGroup]);

  const onSubmit = async (payload) => {
    await dispatch(updateSupplierGroup(id, payload)).then((res) =>
      res !== undefined ? history.push(PATHS.admin.accountDetails) : ""
    );
  };

  return (
    <>
      <Navbar />
      <AdminBar />
      <LightLayout doublebar loading={loading || !supplierGroup}>
        <AccountDetailsForm initialValues={supplierGroup} onSubmit={onSubmit} />
      </LightLayout>
    </>
  );
};

export default EditAccountDetails;
