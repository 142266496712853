import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { PATHS } from "util/appConstants";
import {
  selectZipcode,
  selectZipcodeStatus,
  getZipcode,
  editZipcode,
} from "redux/slices/zipcodeSlice";
import { getTours, selectTours } from "redux/slices/tourSlice";
import ZipcodeForm from "components/Zipcodes/form";
import Navbar from 'components/Navbar';
import LightLayout from 'components/Shared/LightLayout';
import CustomersNavbar from 'components/Masterbar/CustomersBar';

const currentAction = "EDIT";

const EditZipcode = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const loading = useSelector(selectZipcodeStatus);
  const zipcode = useSelector(selectZipcode);
  const tours = useSelector(selectTours);

  useEffect(() => {
    if (id && !loading) {
      dispatch(getZipcode(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (!tours.length) {
      dispatch(getTours());
    }
  }, [dispatch, tours]);

  const onSubmit = async (payload) => {
    await dispatch(editZipcode(id, payload));

    history.push(PATHS.zipcodes.root);
  };

  return (
    <>
      <Navbar />
      <CustomersNavbar />
      <LightLayout doublebar loading={!zipcode}>
        <ZipcodeForm
          initialValues={zipcode}
          onSubmit={onSubmit}
          action={currentAction}
          tourList={tours}
        />
      </LightLayout>
    </>
  );
};
export default EditZipcode;
